import React, {useEffect} from 'react';
import './App.css';
import {createWeb3Modal, defaultConfig, useDisconnect, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider} from '@web3modal/ethers/react'
import {JsonRpcSigner} from 'ethers';
import Notification from "./components/notification";

import Divider from '@mui/material/Divider';
import Dashboard from "./components/dashboard";
import AdminDashboard from "./components/adminDashboard";
import useApi from "./hooks/useApi";
import TermsAndConditionsPopup from "./components/termsAndConditionsPopup";
import Footer from "./components/footer";
import {FallingEmojis} from "falling-emojis";
import {SIGNING_MESSAGE} from "./constants";


const projectId = '8a2cfcc56dcbf5382f842da3910e032e'

const ethersConfig = defaultConfig({
    /*Required*/
    metadata: {
        name: 'DeSci Marketing Bounty',
        description: 'This innovative initiative is designed to revolutionize how users publish decentralized science (DeSci) related content on social media. Transform Your Social Media Activity into Rewards!',
        url: process.env.REACT_APP_URL, // origin must match your domain & subdomain
        icons: ['https://avatars.mywebsite.com/']
    },
    rpcUrl: '...', // used for the Coinbase SDK
    defaultChainId: 1, // used for the Coinbase SDK
})

createWeb3Modal({
    ethersConfig,
    chains: [{
        chainId: 1,
        name: 'Ethereum',
        currency: 'ETH',
        explorerUrl: 'https://etherscan.io',
        rpcUrl: 'https://cloudflare-eth.com'
    }],
    projectId,
})


const App = () => {
    const [notification, setNotification] = React.useState({ message: '', type: '' });
    const [stats, setStats] = React.useState({
        wallet: '0x',
        points: 0,
        posts: 0,
        pendingPosts: 0,
        invitedFriends: 0,
        pendingFriends: 0,
        multiplier: 1.0,
        referalLink: 'http://www.data-lake.co',
        season1: 0
    })
    const [adminStats, setAdminStats] = React.useState({
        wallet: '0x',
        totalPoints: 0,
        totalPosts: 0,
        pendingPosts: 0,
        totalUsers: 0
    })
    const [pendingPosts, setPendingPosts] = React.useState([])
    const {open} = useWeb3Modal()
    const {disconnect} = useDisconnect()
    const {address, isConnected} = useWeb3ModalAccount()
    const {walletProvider} = useWeb3ModalProvider()
    const [signature, setSignature] = React.useState(null)
    const api = useApi()
    const [multipliers, setMultipliers] = React.useState([])
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const adminWallet = process.env.REACT_APP_ADMIN_WALLET.toLowerCase()


    useEffect( () => {
        api.getMultipliers().then(newMultiplier => {
            setMultipliers(newMultiplier
            )
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const connectWallet = async () => {
        try {
            await open({view: 'connect'})
        } catch (e) {
            handleNotification(e)
        }
    };

    const handleNotification = (error, type = 'error') => {
        setNotification({ message: error.message, type: type });
        setTimeout(() => {
            setNotification({ message: '', type: '' });
        }, 5000);
    };


    const handleApprovePost = async (postId) => {
        try {
            await api.approvePost(signature, address, postId)
            await updateStats()
            handleNotification(new Error('Post approved successfully!'), 'success')
        } catch (e) {
            handleNotification(e, 'error')
        }
    }

    const handleRejectPost = async (postId) => {
        try {
            await api.rejectPost(signature, address, postId)
            await updateStats()
            handleNotification(new Error('Post rejected successfully!'), 'success')
        } catch (e) {
            handleNotification(e, 'error')
        }
    }

    const updateStats = async () => {
        if (address.toLowerCase() === adminWallet) {
            const newStats = await api.getAdminStats(signature, address)
            setAdminStats({
                ...newStats
            })
            const pendingPosts = await api.getPendingPosts(signature, address)
            setPendingPosts(pendingPosts)
        } else {
            const newStats = await api.getStats(signature, address)
            setStats({
                ...newStats
            })
        }
    }

    const submitLink = async (link) => {
        try {
            await api.submitLink(signature, address, link)
            handleNotification(new Error('Link submitted successfully!'), 'success')
            await updateStats()
        } catch (e) {
            handleNotification(e, 'error')
        }
    }

    const login = async (pendingSignature) => {
        try {
            if (await api.getLogin(pendingSignature, address, new URLSearchParams(window.location.search).get('ref'))) {
                if (address.toLowerCase() === adminWallet) {
                    const newStats = await api.getAdminStats(pendingSignature, address)
                    setAdminStats({
                        ...newStats
                    })
                    const pendingPosts = await api.getPendingPosts(pendingSignature, address)
                    setPendingPosts(pendingPosts)
                } else {
                    const newStats = await api.getStats(pendingSignature, address)
                    setStats({
                        ...newStats
                    })
                }
                setSignature(pendingSignature)
            } else {
                handleNotification(new Error('Login failed. Invalid signature.'))
                disconnectWallet()
            }
        } catch (e) {
            handleNotification(e)
            disconnectWallet()
        }
    }

    useEffect( () => {
        if (isConnected) {
            const signer = new JsonRpcSigner(walletProvider, address)
            signer.signMessage(SIGNING_MESSAGE+address.toLowerCase()).then((signature) => {
                login(signature.result ? signature.result : signature)
            }).catch(e => {
                handleNotification(e)
                disconnectWallet()
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConnected, ])

    const disconnectWallet = async () => {
        try {
            setSignature(null)
            await disconnect()
        } catch (e) {
            handleNotification(e)
        }
    }

    return (
        <div className="App">
            <div className='falling-emojis'>
            <FallingEmojis emoji={'📸'} />
            </div>

            <Notification message={notification.message} type={notification.type} />
            <div className='header'>
                <span className="partner-text">in partnership with:</span>
            <img src={"/logo.png"} className="logo" alt="logo" />
            </div>
            <header className="App-header">
                <h1 className="animated-header">DeSci Marketing Bounty</h1>
            </header>


            <div className = 'countup'>
                <b>SEASON 1 OVER</b>
                </div>
            {/*
            <CountUp
                start={(TOTAL_POOL / (END_TIMESTAMP - START_TIMESTAMP))*(END_TIMESTAMP - Date.now())}
                end={0}
                duration={(END_TIMESTAMP - Date.now())/1000}
                decimals={4}
                separator=","
            /> LAKE LEFT
    */}


            <Divider className='divider' />

            <TermsAndConditionsPopup isModalOpen={isModalOpen} setIsModalOpen={(e) => {
                setIsModalOpen(e)
            }}
            connectWallet={connectWallet}/>

            {!signature ? (
                <>
                <h2 className='subheader-text'>
                        Connect your wallet today to sign up and earn rewards!
                    </h2>
            <button className="wallet-button" onClick={() => setIsModalOpen(true)}>
                Connect to WalletConnect
            </button>
                </>
                ) : address.toLowerCase() === adminWallet ? (<AdminDashboard stats={adminStats} onClick={disconnectWallet} pendingPosts={pendingPosts} approvePost={(e) => handleApprovePost(e)} rejectPost={(e) => handleRejectPost(e)}/>) : (
                <>
                    <Dashboard stats={stats} onClick={disconnectWallet} multipliers={multipliers} onSubmit={submitLink}/>

</>
                )
            }
    <Footer />
        </div>
    );
};

export default App;
